var generic = generic || {};

$(function(){
  var path = window.location.pathname;
  var pathArray = path.split('/');
  if (generic.cookie('LOCALE') == 'en_HK' && pathArray[1] != 'en-hk' && path.indexOf(".tmpl") == -1 && path.indexOf(".tmpl") == -1) {
        var full_url = window.location.href;
        var appends = ""
        //BugId:254463
	      if(full_url.indexOf("#") > 0)
		  {
			 var full_url_parts = full_url.split("#"); //BugId:254463
			 appends = "#" + full_url_parts[1];
		  }else if(full_url.indexOf("?") > 0){
			 var full_url_parts = full_url.split("?"); 
			 appends = "?" + full_url_parts[1];
		  }
        window.location.href = window.location.protocol + '//' + window.location.host + '/en-hk' + window.location.pathname + appends;
        $("a[data-mp-lang='zh_HK']").removeClass("language-select__link--selected");
        $("a[data-mp-lang='en_HK]").addClass('language-select__link--selected');
  }
  $('.js-switch-lang-link').on('click', function(event) {
      event.preventDefault();
    var lang = $(this).attr('data-mp-lang') === 'en' ? 'en_HK' : 'zh_HK';
      return switchLanguageHK(lang);
  });

  function setWelcomePopup(lang, prevLang) {
    if ($.cookie('hide_popup_offer') && $.cookie('hide_popup_offer_session') === 'hidden' && $.cookie('welcome15_' + prevLang) === undefined) {
      $.cookie('welcome15_' + prevLang, '1', {
        expires: 1,
        path: '/'
      });
      if ($.cookie('welcome15_' + lang) !== '1') {
        $.cookie('hide_popup_offer', '', {expires: -1, path: '/'});
        $.cookie('hide_popup_offer_session', '', {expires: -1, path: '/'});
        sessionStorage.setItem('hide_popup_offer_session', 'null');
      }
    }
  }

  function switchLanguageHK(lang) {
    var domains = { 'zh_HK': '/', 'en_HK': '/en-hk/' };
    var prevLang = generic.cookie('LOCALE') ? generic.cookie('LOCALE') : 'zh_HK';
    if (lang !== prevLang) {
      var path = window.location.pathname.replace(/\/en-hk/, '');
      setWelcomePopup(lang, prevLang);
      document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      generic.cookie('LOCALE', lang, {path: '/'});
      if (path.indexOf('.tmpl') > 0 || path.indexOf('.tmpl') > 0) {
        domains[lang] = '';
      }
      try {
        if (Drupal.settings.elc_common[lang + '_url'] !== undefined && path !== '/') {
          path = Drupal.settings.elc_common[lang + '_url'];
        }
      } catch (error) {
        //None
      }
      if (path === '/') {
        path = '';
      }
      var site_path = window.location.host + domains[lang] + path;
      site_path = site_path.replace(/\/+/g, '/');//BugId:229567 - To remove the extra slashes in the path
      var url = window.location.protocol + '//' + site_path;
      var full_url = window.location.href;
      var appends = '';
      var full_url_parts;
      if (full_url.indexOf('#') > 0) {
        full_url_parts = full_url.split('#'); //BugId:254463
        appends = '#' + full_url_parts[1];
      } else if (full_url.indexOf('?') > 0) {
        full_url_parts = full_url.split('?');
        appends = '?' + full_url_parts[1];
      }
      window.location.href = url + appends;
    }
  }
});
